import { useCallback, useState } from 'react';
import copy from 'clipboard-copy';
import { useAnalytics, Icon } from '@grain/grain-ui';
import { downloadFromUrl } from '@grain/components/support/browser';
import { useStoryToast } from '~/pages/StoryPage/hooks';
import { useDeleteRecording } from '~/modules/recording/hooks';
import { css } from '@emotion/react';
import { useRecordingShareInfoLazyQuery } from '~/pages/Library/library.generated';
import ShareRecordingPermissions from '~/modals/SharePermissions/ShareRecording';
import { AddToCollectionDropdown } from './AddToCollectionDropdown';
import {
  Menu,
  MenuButton,
  Icon20,
  Divider,
  Button,
  Icon16
} from '@grain/grain-ui/v4';

type RecordingQuickActionsButtonProps = {
  recordingId: string;
  recordingTitle: string;
  recordingCollections: { id: string; title: string }[];
  storyCount: number;
  copyUrl: string;
  downloadUrl: string | null;
  disabled?: boolean;
};

export const RecordingQuickActionsButton = ({
  recordingId,
  recordingTitle,
  recordingCollections,
  storyCount,
  copyUrl,
  downloadUrl,
  disabled
}: RecordingQuickActionsButtonProps) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [showAddToPlaylistOptions, setShowAddToPlaylistOptions] =
    useState(false);
  const { trackEvent, getMediaDownloadedTrigger } = useAnalytics();
  const showToast = useStoryToast();
  const [getRecordingShareInfo, { data }] = useRecordingShareInfoLazyQuery();
  const shareRecordingInfo = data?.recording;

  const handleShare = useCallback(() => {
    getRecordingShareInfo({
      variables: { recordingId }
    });
    setShowShareModal(true);
  }, [getRecordingShareInfo, recordingId]);

  const handleDeleteRecording = useDeleteRecording({
    recording: { id: recordingId, title: recordingTitle, storyCount }
  });

  const handleCopyLink = useCallback(() => {
    if (!copyUrl) return;

    copy(copyUrl)
      .then(() => {
        showToast({
          content: 'Recording link copied to clipboard',
          type: 'success',
          uniqueId: 'copied_recording_link'
        });
      })
      .catch(() => {
        showToast({
          content: 'Oops, try again.',
          type: 'failure'
        });
      });
  }, [copyUrl, showToast]);

  const handleDownloadRecording = useCallback(() => {
    if (!downloadUrl) return;

    downloadFromUrl(downloadUrl);

    trackEvent(
      'Media Downloaded',
      {
        trigger: getMediaDownloadedTrigger()
      },
      ['user', 'workspace', `recording:${recordingId}`]
    );
  }, [downloadUrl, recordingId, trackEvent, getMediaDownloadedTrigger]);

  // Ensure that we reset the dropdown after the animation has finished
  const delayedHideAddToPlaylistOptions = useCallback(() => {
    setTimeout(setShowAddToPlaylistOptions, 300, false);
  }, []);

  return (
    <>
      <Menu
        css={css`
          width: 230px;
        `}
        tippyProps={{
          placement: 'bottom-end',
          interactive: true,
          onHide: delayedHideAddToPlaylistOptions,
          onShow: instance => {
            // This dropdown has a mix of interactive and non-interactive items.
            // We need to close the dropdown if a non-interactive item is clicked.
            instance.popper.addEventListener('click', event => {
              const target = event.target as HTMLElement;
              const shouldClose = target.closest('.close-tooltip') !== null;
              if (shouldClose) {
                instance.hide();
              }
            });
          }
        }}
        content={
          !showAddToPlaylistOptions ? (
            <>
              <MenuButton
                className='close-tooltip'
                label='Copy link'
                textLabelProps={{ startIcon: Icon.CopyLink }}
                onClick={handleCopyLink}
              />
              <MenuButton
                className='close-tooltip'
                label='Share'
                textLabelProps={{ startIcon: Icon20.Share }}
                onClick={handleShare}
              />
              <MenuButton
                label='Add to playlist'
                textLabelProps={{ startIcon: Icon20.Playlists }}
                onClick={() => setShowAddToPlaylistOptions(true)}
              />
              {downloadUrl && (
                <MenuButton
                  className='close-tooltip'
                  label='Download'
                  textLabelProps={{ startIcon: Icon20.Download }}
                  onClick={handleDownloadRecording}
                  disabled={!downloadUrl}
                />
              )}
              <>
                <Divider />
                <MenuButton
                  className='close-tooltip'
                  label='Delete'
                  textLabelProps={{ startIcon: Icon20.Delete }}
                  onClick={handleDeleteRecording}
                />
              </>
            </>
          ) : (
            <AddToCollectionDropdown
              recordingCollections={recordingCollections}
              recordingId={recordingId}
            />
          )
        }
      >
        <Button
          variant='ghost'
          data-cy='menu-more-button'
          disabled={disabled}
          textLabelProps={{ startIcon: Icon16.Overflow }}
        />
      </Menu>
      {showShareModal && shareRecordingInfo && (
        <ShareRecordingPermissions
          recording={shareRecordingInfo}
          onCancel={() => setShowShareModal(false)}
        />
      )}
    </>
  );
};
