import { css } from '@emotion/react';
import {
  Button,
  ButtonRow,
  Icon16,
  TextLabel,
  theme,
  Tooltip
} from '@grain/grain-ui/v4';
import { useAnalytics, useShowToast } from '@grain/grain-ui';
import {
  IntelligenceChatMessageFeedbackType,
  IntelligenceChatMessageStatus
} from '@g/schema';
import copy from 'clipboard-copy';
import { AssistantChatMessageMarkdown } from './AssistantChatMessageMarkdown';
import { StyledGrainLogo } from '../styled';
import { ChatMessageWithAugmentedCitations } from '../types';
import { useToggleRecordingChatMessageFeedbackMutation } from '../data';
import { Feedback, FeedbackData, FeedbackType } from '~/components/Feedback';

const EMPTY_ARRAY: never[] = [];

type AssistantChatMessageProps = {
  chatMessage: ChatMessageWithAugmentedCitations;
  includedInContext: boolean;
};

export const AssistantChatMessage = ({
  chatMessage,
  includedInContext
}: AssistantChatMessageProps) => {
  const { trackEvent } = useAnalytics();
  const [toggleFeedback] =
    useToggleRecordingChatMessageFeedbackMutation(chatMessage);
  const showToast = useShowToast();

  if (
    chatMessage.status === IntelligenceChatMessageStatus.Processing &&
    !chatMessage.content
  ) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.xs};
        `}
      >
        <StyledGrainLogo />

        <TextLabel
          size='md'
          css={css`
            color: ${theme.tokens.color.textSecondary};
          `}
        >
          Thinking...
        </TextLabel>
      </div>
    );
  }

  const getFeedbackType = (): FeedbackType => {
    return chatMessage.feedback?.type ===
      IntelligenceChatMessageFeedbackType.Positive
      ? 'positive'
      : 'negative';
  };

  const feedbackData: FeedbackData | undefined = chatMessage.feedback
    ? {
        id: chatMessage.id,
        type: getFeedbackType(),
        feedback: chatMessage.feedback.feedback || undefined
      }
    : undefined;

  const handleFeedbackSubmit = (data: FeedbackData) => {
    if (!data.id) {
      return; // This should never happen
    }

    toggleFeedback({
      chatMessageId: data.id,
      type:
        data.type === 'positive'
          ? IntelligenceChatMessageFeedbackType.Positive
          : IntelligenceChatMessageFeedbackType.Negative,
      feedback: data.feedback
    });
  };

  return (
    <TextLabel
      startIcon={() => <StyledGrainLogo />}
      css={css`
        align-items: start;

        ${!includedInContext &&
        css`
          opacity: 0.5;
        `}
      `}
    >
      <AssistantChatMessageMarkdown
        citations={chatMessage.citations ?? EMPTY_ARRAY}
      >
        {chatMessage.content}
      </AssistantChatMessageMarkdown>
      {chatMessage.status === IntelligenceChatMessageStatus.Completed && (
        <ButtonRow>
          <Tooltip content='Copy'>
            <Button
              size='sm'
              variant='ghost'
              onClick={() => {
                copy(chatMessage.content ?? '');
                showToast({
                  content: 'Response copied to clipboard',
                  type: 'success'
                });
                trackEvent('Button Clicked', {
                  button_name: 'ask_copy',
                  trigger: 'ask_recording'
                });
              }}
              textLabelProps={{ startIcon: Icon16.Copy }}
            />
          </Tooltip>
          <Feedback
            id={chatMessage.id}
            feedbackData={feedbackData}
            onFeedbackSubmit={handleFeedbackSubmit}
            analyticsTrigger='ask_recording'
          />
        </ButtonRow>
      )}
    </TextLabel>
  );
};
